// src/admin_elements/calculations/OnCosts/sections/OnCostsMidLevel.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchOnCostsMidLevel } from '../../../../services/api/fetchOnCostsMidLevel';

import styles from '../OncostsCalculations.module.css'; // Ensure CSS is correctly referenced

const OnCostsMidLevel = ({ dataAgreementId, quarterDate }) => {
  const [midData, setMidData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadMidLevelData();
    }

  }, [dataAgreementId, quarterDate]);

  const loadMidLevelData = async () => {
    setLoading(true);
    setError(null);
    setMidData([]);

    try {
      const data = await fetchOnCostsMidLevel(dataAgreementId, quarterDate);
      setMidData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h3>Mid-Level On Costs Summary</h3>

      {loading && <p>Loading mid-level on costs data...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {midData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>On Costs Item</th>
              <th>On Costs Order</th>
              <th>Type Name</th>
              <th>No. of Units Per Annum</th>
              <th>Amount</th>
              <th>Level 1 Class</th>
              <th>Level 2 Class</th>
              <th>Level 3 Class</th>
            </tr>
          </thead>
          <tbody>
            {midData.map((row, index) => (
              <tr key={`${row.quarterdate}-${row.oncosts_item}-${index}`}>
                <td>{row.quarterdate}</td>
                <td>{row.oncosts_item}</td>
                <td>{row.oncosts_order}</td>
                <td>{row.typename}</td>
                <td>{row.no_of_units_per_annum}</td>
                <td>{row.amount}</td>
                <td>{row.level1_class}</td>
                <td>{row.level2_class}</td>
                <td>{row.level3_class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && !error && <p>No mid-level on costs data found.</p>
      )}
    </div>
  );
};

OnCostsMidLevel.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quarterDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default OnCostsMidLevel;
