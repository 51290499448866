// src/services/api/onCosts/fetchOnCostsTopLevel.js

import { supabase } from '../supabaseClient';

/**
 * Fetch top-level on costs data, filtered by dataAgreementId and quarterDate.
 *
 * @param {number|string} dataAgreementId - The Data Agreement ID to filter by.
 * @param {string} quarterDate - The Quarter Date to filter by (format YYYY-MM-DD).
 * @returns {Promise<Array>} - An array of top-level on costs data.
 * @throws {Error} - Throws error if fetching data fails.
 */
export const fetchOnCostsTopLevel = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    throw new Error('dataAgreementId and quarterDate are required.');
  }

  try {
    const { data, error } = await supabase
      .from('vw_oncosts_sum_v1')
      .select('quarterdate, level1_class, level2_class, level3_class')
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate)
      .order('quarterdate', { ascending: true }); // Adjust ordering if needed

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching On Costs top-level data:', error.message);
    throw error;
  }
};
