// src/admin_elements/calculations/OnCosts/OnCostsCalculations.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './OncostsCalculations.module.css'; // Ensure CSS is correctly referenced

// Sub-sections
import OnCostsTopLevel from './sections/OnCostsTopLevel';
import OnCostsMidLevel from './sections/OnCostsMidLevel';
import OnCostsGranular from './sections/OnCostsGranular';

const OnCostsCalculations = ({ dataAgreementId, tradepersonId, quarterDate }) => {
  // Accordion toggles
  const [topOpen, setTopOpen] = useState(true);
  const [midOpen, setMidOpen] = useState(false);
  const [granOpen, setGranOpen] = useState(false);

  return (
    <div className={styles.accordionContainer}>
      <h2>On Costs Calculations</h2>

      {/* TOP LEVEL */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setTopOpen((prev) => !prev)}
        >
          <span>Top-Level Summary</span>
          <span className={styles.toggleIcon}>{topOpen ? '-' : '+'}</span>
        </div>
        {topOpen && (
          <div className={styles.accordionContent}>
            <OnCostsTopLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate}
            />
          </div>
        )}
      </div>

      {/* MID LEVEL */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setMidOpen((prev) => !prev)}
        >
          <span>Mid-Level Summary</span>
          <span className={styles.toggleIcon}>{midOpen ? '-' : '+'}</span>
        </div>
        {midOpen && (
          <div className={styles.accordionContent}>
            <OnCostsMidLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate}
            />
          </div>
        )}
      </div>

      {/* GRANULAR */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setGranOpen((prev) => !prev)}
        >
          <span>Granular Data</span>
          <span className={styles.toggleIcon}>{granOpen ? '-' : '+'}</span>
        </div>
        {granOpen && (
          <div className={styles.accordionContent}>
            <OnCostsGranular
              dataAgreementId={dataAgreementId}
              tradepersonId={tradepersonId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// PropTypes for type checking and ensuring required props are provided
OnCostsCalculations.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quarterDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default OnCostsCalculations;
