// src/admin_elements/Calculations/Oncosts/OnCostsGraph.jsx

import React from 'react';
import CalculationGraph from '../CalculationGraph'; // Adjust the import path as necessary

const CostsGraph = () => {
  return <CalculationGraph calcType="Oncosts" />;
};

export default CostsGraph;
